import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CustomDateWithoutSuffix'
})
export class CustomDateWithoutSuffixPipe implements PipeTransform {

  transform(dateString: string): string {
    const date = new Date(dateString);
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
   
    
    // Format the date as "Month dayth, year"
    return `${month} ${day}, ${year}`;
  }

}
