import { Component, Input,NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Company } from '../company-settings/shared/models/Company';
import { CompanyManagementService } from '../company-settings/shared/services/companyManagement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LabelService } from 'src/app/admin-tools/label-management/shared/services/label.service';
import { SpinnerService } from 'src/core/services/spinner.service';
import { Label } from 'src/app/admin-tools/label-management/shared/models/label.model';
import { catchError, finalize, Subscription, throwError } from 'rxjs';
import { MessageService } from 'primeng/api';



@Component({
  selector: 'app-super-admin-settings',
  templateUrl: './super-admin-settings.component.html',
  styleUrls: ['./super-admin-settings.component.scss']
})
export class SuperAdminSettingsComponent {
  public _companyDetails!: Company;

  companyForm: FormGroup;
animationState: string = '';
displayDeleteLabelDialog: boolean = false;
showError: boolean = false;
isInputValid: boolean = false;
selectedLabel: string='';
deleteLabelValue: string = '';
parentLabels: any[] = [];
organization: string = 'dk';
CollectDirectly: boolean = false ;
Denmark: boolean = false ;
Norway: boolean = false ;
useNemkonto: boolean = false ;
toggleSwitchIdentifier: boolean = false ;
editLabelSubscription!: Subscription;
currentUser: any;
labelId!: string; 
selectedParedBoardId!: string; 


@Input() set companyDetails(value: Company) {
  this._companyDetails = value;
  this.CollectDirectly = this._companyDetails.collectDirectly?? false;
  this.Denmark = this._companyDetails.registeredCountry =='Denmark'?true: false;
  this.Norway = this._companyDetails.registeredCountry =='Norway'?true: false;
  this.toggleSwitchIdentifier= (!this._companyDetails.financialIdentifier )?true:false;
  //console.log(this.CollectDirectly);
  if (this._companyDetails) {
    this.companyForm.patchValue(this._companyDetails);
    this.selectedLabel = this._companyDetails.brandName ? this._companyDetails.brandName : 'Default Label';
  } 
  if(!this.CollectDirectly)
    this.getParentLabels();
}
ngOnInit() {
  this.organization = sessionStorage.getItem('organisation')!;
  this.currentUser = JSON.parse(localStorage.getItem('currentUser')!);
  this.labelId = this.route.snapshot.params.id;
  
}

constructor( private route: ActivatedRoute,  private router: Router,private labelService: LabelService,private messageService: MessageService,
  private spinnerService: SpinnerService,private fb: FormBuilder,private companyService: CompanyManagementService) {
  this.companyForm = this.fb.group({
    memberType: ['', Validators.required],
    labelType: ['', Validators.required],
    collectDirectly: [''] ,
    parentBoardId: [''] ,
    useNemkonto: [''] ,
    dK_RegNo: [''] ,
    dK_AccountNo: [''] ,
    nO_AccountNo: [''] ,
    Intl_NameOfBank: [''] ,
    Intl_IBAN: [''] ,
    Intl_Swicfbic: [''] ,
    ifpiDanmark: [false] ,
    ifpiNorge: [false] ,
    fono: [false] ,
    financialIdentifier: ['', { value: '', disabled: this.toggleSwitchIdentifier }] ,
   
  });
  
}


ngAfterViewInit() {
  if (this.companyForm) {
    this.companyForm.valueChanges.subscribe((_) => {
      if (this.companyForm.dirty) {
       // this.reportsFormChange = true;
        // remove slide-down from div of class unsaved-changes-alert
        this.animationState = 'slide-up';
      }
    });
  } else {
    console.error('ngForm is not available');
  }
}
toggleCollectDirectly()
{
  this.CollectDirectly=!this.CollectDirectly;
}
toggleUseNemkonto()
{
  this.useNemkonto=!this.useNemkonto;
}
onToggleChange(event:Event){

  this.toggleSwitchIdentifier = !this.toggleSwitchIdentifier;
  //console.log(this.toggleSwitch);
  this.companyForm.patchValue({
    financialIdentifier:(this.toggleSwitchIdentifier )?this._companyDetails.vatid:this._companyDetails.financialIdentifier
  });
  this.UpdateCompany();
 // this.toggleSwitchIdentifier = !this.toggleSwitchIdentifier;
 this.getcompany();
  }
checkInputContent() {
  this.isInputValid = this.deleteLabelValue === this.selectedLabel;
  this.showError = !this.isInputValid && this.deleteLabelValue !== '';
}
  public cancel():void{
    this.animationState = 'slide-down';
      setTimeout(() => {
        // Hide the save changes alert
        //this.reportsFormChange = false;
        //this.getDistribution();
        // how to clear  newDistributionForm  dirty
        this.companyForm.markAsPristine();
        this.animationState = '';
      }, 200);
      this.companyForm.patchValue(this._companyDetails);
  }
   
  UpdateCompany()
  {
    // Convert form value to Company instance
    this.spinnerService.show();
    const company: Company = {
      ...this._companyDetails,
      labelType : this.companyForm.value.labelType,
      memberType : this.companyForm.value.memberType,
      collectDirectly : this.companyForm.value.collectDirectly,
      dK_RegNo : this.companyForm.value.dK_RegNo,
      dK_AccountNo : this.companyForm.value.dK_AccountNo,
      nO_AccountNo : this.companyForm.value.nO_AccountNo,
      Intl_IBAN : this.companyForm.value.Intl_IBAN,
      Intl_NameOfBank : this.companyForm.value.Intl_NameOfBank,
      Intl_Swicfbic : this.companyForm.value.Intl_Swicfbic,
      financialIdentifier : this.companyForm.value.financialIdentifier,
      iFPIDanmark : this.companyForm.value.ifpiDanmark,
      iFPINorge : this.companyForm.value.ifpiNorge,
      fONO : this.companyForm.value.fono
       };
    this.companyService.UpdateCompany(company).subscribe({
      next: (response) => {
        if(this.companyForm.value.collectDirectly !== this.CollectDirectly)
        {
          (this.companyForm.value.collectDirectly == false) ?this.getParentLabels():null       
          this.editLabel('collect');
        }
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'success',
          summary: 'Settings Saved',
          detail: 'Your changes have been Successfully Updated.',
        });
      },
      error: (error) => {
        this.spinnerService.hide();
        this.messageService.add({
          severity: 'error',
          summary: 'Settings Saved Error',
          detail: 'Please Try Again.',
        });
      }
    });
    this.animationState = 'slide-down';
    setTimeout(() => {
      // Hide the save changes alert
      this.companyForm.markAsPristine();
      this.animationState = '';
    }, 200);
  }

  editLabel(typeChange:string) {
    let labelData: Label = {      
      "id": this.labelId,  
      "name": this._companyDetails.brandName??'',
      "hasImage":false, 
      "isCollectingForItself":typeChange=='collect'? this.companyForm.value.collectDirectly:this._companyDetails.isCollectingForItself,    
    "parentBoardId": typeChange !== 'collect' 
  ? (this.selectedParedBoardId ?? this.currentUser.board.id) // Ensure it's a string
  : this._companyDetails.parentBoardId??''
    };

    this.editLabelSubscription = this.labelService.editLabel(labelData).pipe(
      catchError((error) => {
        if (error.status === 403) {
          this.router.navigate(['/login']);
          console.error('Unauthorized request');
        }
        return throwError(() => {
          console.log(error);
        }); // rethrow the error after handling
      })
    ).subscribe(

    );
  }
  openDeleteLabelDialog() {
    //this.selectedLabel = label;
    this.displayDeleteLabelDialog = true;
  }

  closeDeleteLabelDialog() {
    this.displayDeleteLabelDialog = false;
  }

  deleteLabel() {
    const companyBoardId = this._companyDetails.boardId != null?this._companyDetails.boardId :'';
    var boardId = JSON.parse(localStorage.getItem('currentUser')!).board.id;
    this.spinnerService.show();
    this.labelService
      .deleteLabel(boardId, companyBoardId)
      .pipe(finalize(() => this.closeDeleteLabelDialog()))
      .subscribe({
        next: (message) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Delete Label',
            detail: 'Deleted Successfuly',
          });
       
          this.spinnerService.hide();
        },
        error: (error) => {
          this.spinnerService.hide();
        },
      });
  }

  getParentLabels() {
    this.spinnerService.show();
   let organisationId =this.organization == 'dk'?'mpo':'Music Nest Norway' ;
   let userBoardId = this.currentUser.board.id;
    const subscription = this.companyService.GetParentBoards(organisationId,userBoardId)
    .subscribe((res: any) => {
      this.parentLabels=res;
      this.spinnerService.hide();
    });
  }



getcompany()
{
  this.companyService.getCompanybyBoardID(this.companyForm.value.boardId).subscribe({
    next: (companyData) => {
      // Handle the response data as needed
      console.log('Company data:', companyData);
    },
    error: (error) => {
      // Handle the error as needed
      console.error('Error fetching company data:', error);
    }
  });
}
  onParentLabelChange(e:any)
  {
    this.selectedParedBoardId= e.value; // Get the selected value
    this.editLabel(this.selectedParedBoardId);
  }


}
