import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppConfig } from 'src/app/app.config';
import { GetDistributionEmailInformation } from '../models/distribution.model';

@Injectable({
  providedIn: 'root',
})
export class DistributionService {
  protected distributionServiceUrl = AppConfig.settings.apiServer.distributionServiceUrl;
  distroName : string | null = null;
  editMode : boolean = false;
  constructor(
    private http: HttpClient,
  ) { }

  setEditMode(isEditMode: boolean) {
    this.editMode = isEditMode;
  }

  getEditMode(): boolean {
    return this.editMode;
  }
  // get distributions
  getDistributions(organizationId: string, pageSize: number, pageIndex: number) {
    const url = `${this.distributionServiceUrl}/GetAllDistributionsByOrganizationId?OrganizationId=${organizationId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
    return this.http.get(url);
  }

  // create new distribution
  createDistribution() {
    const url = `${this.distributionServiceUrl}/CreateDistribution`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const date = (new Date()).toISOString().replace(/[^0-9]/g, '').slice(0, -3);
    const currentYear = new Date().getFullYear();
    const body = JSON.stringify({
      title: `New Distribution_${date.substring(0, 8)}_${date.substring(8, 15)}`,
      description: 'New Distribution description',
      distributionYear: `${currentYear}`,
      //distributionId: `U11_${currentYear % 100 + '_' + Math.floor(Math.random() * 10000)}`,
      usages: '',
      organizationId: JSON.parse(localStorage.getItem('currentUser')!).board.organisationId
    });
    return this.http.post(url, body, { headers });
  }

  // upload distribution report 
  uploadDistributionReport(distributionData: any) {
    let bodyContent = new FormData();

    for (let key in distributionData) {
      bodyContent.append(key, distributionData[key]);
    }

    bodyContent.append('columnMappingInput', JSON.stringify({
      Artist: 'UIArtist',
      Album: 'UIAlbum',
      Track: 'UITrack',
      ISRC: 'UIIsrc',
      AssignedTo: 'UIAssignedTo',
      CollectingLabel: 'UICollectingLabel',
      Status: 'Status',
      Streams: 'Streams (or duration)',
      Finance: 'Finance',
    }));

    const url = `${this.distributionServiceUrl}/UploadDistributionReport`;
    return this.http.post(url, bodyContent);
  }

  reuploadDistributionReport(distributionData: any) {
    let bodyContent = new FormData();

    for (let key in distributionData) {
      bodyContent.append(key, distributionData[key]);
    }

    bodyContent.append('columnMappingInput', JSON.stringify({
      Artist: 'UIArtist',
      Album: 'UIAlbum',
      Track: 'UITrack',
      ISRC: 'UIIsrc',
      AssignedTo: 'UIAssignedTo',
      CollectingLabel: 'UICollectingLabel',
      Status: 'Status',
      Streams: 'Streams (or duration)',
      Finance: 'Finance',
    }));

    const url = `${this.distributionServiceUrl}/re-uploadDistributionReport`;
    return this.http.post(url, bodyContent);
  }

  // delete distribution report
  deleteDistributionReport(distributionId: string, distributionReportId: string) {
    //const url ='https://localhost:7026/api/Distributions/DeleteDistributionReport?DistributionId=${distributionId}&DistributionReportId=${distributionReportId}';
    const url = `${this.distributionServiceUrl}/DeleteDistributionReport?DistributionId=${distributionId}&DistributionReportId=${distributionReportId}`;
    return this.http.delete(url);
  }

  // get distribution
  getDistribution(distributionId: string) {
    const url = `${this.distributionServiceUrl}/GetDistributionById?distributionId=${distributionId}`;
    return this.http.get(url).pipe(
      map(this.distributionConverter)
    );
  }

  updateDistribution(distributionData: any) {
    const url = `${this.distributionServiceUrl}/UpdateDistribution`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(distributionData);
    return this.http.patch(url, body, { headers });
  }

  // Individual collector sums - upload on account or with holding sheet
  uploadOnAccountOrWithHoldingSheet(distributionData: any) {
    let bodyContent = new FormData();

    for (let key in distributionData) {
      bodyContent.append(key, distributionData[key]);
    }

    bodyContent.append('columnMappingInput', JSON.stringify({
      Label: 'Label',
      Value: 'Value',
    }));

    const url = `${this.distributionServiceUrl}/UploadOnAccountOrWithHoldingSheet`;
    return this.http.post(url, bodyContent);
  }

  // reorder distribution reports
  reorderDistributionReports(reportsOrder: { distributionReportId: string, reportOrder: number }[]) {
    const url = `${this.distributionServiceUrl}/ReorderDistributionReports`;
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.patch(url, reportsOrder, { headers });
  }

  // upload income streams
  uploadIncomeStreamsSheet(distributionId: string, file: File) {
    let bodyContent = new FormData();
    bodyContent.append('DistributionId', distributionId);
    bodyContent.append('file', file);

    const url = `${this.distributionServiceUrl}/UploadIncomeStreamsSheet`;
    return this.http.post(url, bodyContent);
  }

  // update distribution reports
  // {

  //   "distributionReportId": "B728DD33-56AB-429A-B3E7-EF99516570B6",
  //   "name": "Streaming 2022 Test",
  //   "year": "2027",
  //   "split": "25"
  // }
  updateDistributionReports(distributionData: any) {
    const url = `${this.distributionServiceUrl}/UpdateDistributionReports`;
    const headers = {
      'Content-Type': 'application/json',
    };
    const body = JSON.stringify(distributionData);
    return this.http.patch(url, body, { headers });
  }

  distributionConverter(data: any) {
    if (data.distributionReports) {
      data.distributionReports = data.distributionReports.map((report: any) => {
        return {
          id: report.id,
          reporter: report.reporter,
          name: report.name,
          year: report.year,
          splitPercentage: report.split,
          tracks: report.trackCount,
          assigned: report.assignedCount,
          unassigned: report.unassignedCount,
          streamsDuration: report.streamsDurationCount,
          financialValue: report.financialValue ? report.financialValue.toFixed(4) : 0,
          originFinanceValue: report.originFinanceValue ? report.originFinanceValue.toFixed(4) : 0,
        }
      });
    }
    return data;
  }
  approveDistribution(distributionId: string) {
    const url = `${this.distributionServiceUrl}/ApproveDistribution?DistributionId=${distributionId}`;
 
    return this.http.patch(url, {});
  }
publishDistribution(distributionId: string) {
  const url = `${this.distributionServiceUrl}/PublishDistribution?DistributionId=${distributionId}`;
  return this.http.patch(url,{});
}
sendDistributionNotificationEmail(distributionId: string) {
  const url = `${this.distributionServiceUrl}/SendDistributionNotificationEmails?DistributionId=${distributionId}`;
  return this.http.post(url, {});
}
getDistributionEmailInformation(distributionId: string) { 
  const url = `${this.distributionServiceUrl}/GetDistributionEmailInformation?DistributionId=${distributionId}`;

  return this.http.get<GetDistributionEmailInformation[]>(url);
}
GetOriginDistributionsByOrganizationId(organizationId: string):Observable<any> {
  const url = `${this.distributionServiceUrl}/GetOriginDistributionsByOrganizationId?OrganizationId=${organizationId}`;
  return this.http.get<any>(url).pipe(
    map(response => {    
      
      return response ;
    })
  );
}
updateResidualDistribution(distributionData: any) {
  const url = `${this.distributionServiceUrl}/UpdateResidualDistribution`;
  const headers = {
    'Content-Type': 'application/json',
  };
  const body = JSON.stringify(distributionData);
  return this.http.patch(url, body, { headers });
}
}