<div class="pageContainer statements d-flex flex-column gap-4">
  <p-toolbar styleClass="tablePageHeader">
    <ng-template pTemplate="left">
      <div>
        <h2><span class="screen-header">Finance</span></h2>
        <p>
          <span class="screen-sub-header">{{ LabelName }}</span>
        </p>
      </div>
    </ng-template>
    <ng-template pTemplate="right">
      <span *ngIf="isSuperAdmin">View as </span>
      <p-dropdown
        *ngIf="isSuperAdmin"
        [options]="distributionReportLabels"
        optionLabel="labelName"
        [(ngModel)]="selectedDistributionReportLabel"
        placeholder="Select a distribution report"
        (onChange)="getDistribution()"
        appendTo="body"
        class="m-2"
      ></p-dropdown>
      <button
        *ngIf="isSuperAdmin"
        pButton
        type="button"
        icon="pi pi-angle-left"
        class="p-button-outline btn-nav"
        [disabled]="currentLabelIndex == 0"
        (click)="getPreviousLabelData()"
      ></button>

      <button
        *ngIf="isSuperAdmin"
        pButton
        type="button"
        icon="pi pi-angle-right"
        class="p-button-outline btn-nav"
        [disabled]="currentLabelIndex == distributionReportLabels.length - 1"
        (click)="getNextLabelData()"
      ></button>

      <div
        class="d-flex gap-1"
        *ngIf="hasSubLabelsInDistribution && isSuperAdmin"
      >
        <p-inputSwitch
          id="isAggregated"
          [(ngModel)]="isAggregated"
          (onChange)="getDistribution()"
        />
        <label class="fw-bold" for="isAggregated">Is Accumulated</label>
      </div>
    </ng-template>
  </p-toolbar>

  <p-breadcrumb [model]="items" *ngIf="!isSuperAdmin"></p-breadcrumb>

  <p-panel styleClass="green-bar-panel" [toggleable]="false">
    <ng-template pTemplate="body">
      <div class="row g-3">
        <div class="col" style="min-width: 100px">
          <div>
            <h5 class="fw-bold mt-2 mb-3 ms-2">
              {{ financeTitle }}
            </h5>
            <p class="ms-2 mb-5">{{ financeDetails }}</p>
          </div>
          <hr class="dashed" />
          <div>
            <h5 class="fw-bold mt-2 mb-3 ms-2">Export</h5>
            <div class="row pb-2">
              <div class="col">
                <button
                  (click)="exportFinanceStatement()"
                  [disabled]="financeStatementLoading"
                  class="statement-download-btn"
                >
                  <span class="spinner" *ngIf="financeStatementLoading"></span
                  >Statement (PDF)
                </button>
              </div>
              <div class="col" *ngIf="isAdministrationFeesIncluded">
                <!-- <input
                  type="button"
                  value="VAT invoice (PDF)"
                  (click)="downloadInvoicePdf()"
                  class="statement-download-btn"
                /> -->
                <button
                  (click)="downloadInvoicePdf()"
                  [disabled]="downloadInvoiceLoading"
                  class="statement-download-btn"
                >
                  <span class="spinner" *ngIf="downloadInvoiceLoading"></span
                  >VAT invoice (PDF)
                </button>
              </div>
              <div class="col">
                <!-- <input
                  type="button"
                  value="Track details"
                  (click)="exportLabelTracks()"
                  class="statement-download-btn"
                /> -->

                <button
                  (click)="exportLabelTracks()"
                  [disabled]="exportLoading"
                  class="statement-download-btn"
                >
                  <span class="spinner" *ngIf="exportLoading"></span>Track
                  details
                </button>
              </div>
            </div>
          </div>

          <!-- <p-divider type="dashed" [style]="{ width: '96%' }" /> -->
          <hr class="dashed" />

          <div>
            <div>
              <div>
                <div class="input-group trackdetails-group">
                  <h5 class="fw-bold mt-2 mb-3 ms-2">Track details</h5>

                  <!-- <input type="text" class="form-control" placeholder="Search for track..." /> -->
                  <!-- <p-button
                    icon="pi pi-cloud-download"
                    label="Export track details"
                    class="ms-auto"
                    styleClass="p-button-outline fw-bold"
                    [loading]="exportLoading"
                    (onClick)="exportLabelTracks()"
                  ></p-button> -->
                </div>
              </div>
              <!-- <div class="card-body"> -->
              <div>
                <div class="mpo-table mpo-table-reports">
                  <p-table
                    [value]="tracks"
                    [paginator]="true"
                    [rows]="currentPageSize"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    class="tracks-table"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th class="fw-bold">Artist</th>
                        <th class="fw-bold">Release</th>
                        <th class="fw-bold">Title</th>
                        <th class="fw-bold">ISRC</th>
                        <th class="fw-bold">Label</th>
                        <th class="fw-bold">Value (ex. VAT)</th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-track>
                      <tr>
                        <td>{{ track.artistName }}</td>
                        <td>{{ track.albumName }}</td>
                        <td>{{ track.trackName }}</td>
                        <td>{{ track.isrc }}</td>
                        <td>{{ track.assignedTo }}</td>
                        <td>
                          {{
                            (track.finance | number : "1.2-2" : "es") +
                              " " +
                              currency
                          }}
                        </td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="8">
                          <p class="empty-records">No records found</p>
                        </td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-5 d-flex flex-column gap-3 statementsidebar">
          <div
            class="gray-card"
            *ngIf="
              isAggregated && isParentLabel != '1' && hasSubLabelsInDistribution
            "
          >
            <P>
              This financial statement offers an
              <span class="fw-bold">accumulated view</span>, covering all
              subaccounts where your label
              <span class="fw-bold">is the collector</span>. <br /><br />The VAT
              amounts reflect the total from
              <span class="fw-bold">individual statements</span> and any
              applicable VAT rules applied to
              <span class="fw-bold">each subaccount</span>.
            </P>
          </div>

          <div class="colored-card">
            <h4>
              Total remuneration<span *ngIf="!distributionHasCorrection">
                transferred</span
              >
            </h4>
            <h3>
              {{
                (labelRemuneratedAmount | number : "1.2-2" : "es") +
                  " " +
                  currency
              }}
            </h3>
            <p class="collectedby" *ngIf="!isCollectingToItself">
              Collected by {{ collectingLabel }}
            </p>
            <p
              class="notice-card"
              *ngIf="showRemureationAlert && !distributionHasCorrection"
            >
              As remuneration is below <b>300 DKK </b> the remuneration will be
              brought forward to a later distribution.
            </p>
            <div
              class="correction-card"
              *ngIf="distributionHasCorrection"
              [innerHTML]="distributionCorrectionMessage"
            ></div>
          </div>
          <div class="row">
            <!-- <div class="col-6">
              <p-button label="Download data (.xlsx)" styleClass="p-button-success" [style]="{'width': '100%'}" 
              (click)="downloadData()"/>
            </div> -->
            <!-- <div class="col-12">
              <p-button
                *ngIf="isAdministrationFeesIncluded"
                [label]="(isAggregated && isParentLabel != '1' && hasSubLabelsInDistribution) ? 'Download all invoices (PDF)':'Download invoice (PDF)'"
                styleClass="p-button-success"
                [style]="{ width: '100%' }"
                [loading]="downloadInvoiceLoading"
                iconPos="right"
                (click)="downloadInvoicePdf()"
              />
              
            </div> -->
          </div>

          <div class="row">
            <div class="col-6">
              <div class="uncolored-card">
                <h6 class="fw-bold">
                  {{
                    !distributionHasCorrection
                      ? "Payment transfer date"
                      : "Published date"
                  }}
                </h6>
                <p>
                  {{ transferDate ? (transferDate | date : "d/M yyyy") : "-" }}
                </p>
              </div>
            </div>
            <div class="col-6">
              <div class="uncolored-card">
                <h6 class="fw-bold">Distribution ID</h6>
                <p>{{ distributionId }}</p>
              </div>
            </div>
          </div>

          <div class="mt-2 uncolored-card" *ngIf="usages.length > 0">
            <h6 class="fw-bold">Usage(s)</h6>
            <div class="d-flex flex-wrap gap-2 p-1 fin-usages">
              <span class="badge" *ngFor="let usage of usages">{{
                usage
              }}</span>
            </div>
          </div>

          <div class="uncolored-card" *ngIf="vatStatement">
            <h6 class="fw-bold">VAT</h6>
            <p>{{ vatStatement }}</p>
          </div>

          <div class="uncolored-card" *ngIf="royaltyStatement">
            <h6 class="fw-bold">Royalty</h6>
            <p>{{ royaltyStatement }}</p>
          </div>

          <p-panel
            styleClass="summary-panel"
            header="Your distribution"
            [toggleable]="true"
          >
            <!-- add custom header -->

            <div class="details">
              <div class="summary-item">
                <p>Your percentage share</p>
                <p>
                  {{ (LabelSharePercentage | number : "1.6-6" : "es") + " %" }}
                </p>
              </div>
              <p-divider type="dashed" />

              <div class="summary-item">
                <p *ngIf="!isResidual">Received Remuneration</p>
                <p *ngIf="isResidual">Received funds</p>
                <p>
                  {{
                    (receivedFunds | number : "1.2-2" : "es") + " " + currency
                  }}
                </p>
              </div>

              <div class="summary-item">
                <p *ngIf="this.reservedCostsType !== 'percent' &&  !isResidual">
                  Reserved Remuneration
                </p>
                <p *ngIf="this.reservedCostsType !== 'percent' &&  isResidual">
                  Reserved costs
                </p>
                <p *ngIf="this.reservedCostsType !== 'percent'">
                  {{
                    (reservedCosts | number : "1.2-2" : "es") + " " + currency
                  }}
                </p>

                <p *ngIf="this.reservedCostsType === 'percent' && showDetails && !isResidual">
                  Reserved Remuneration ({{ reservedCosts }}%)
                </p>
                <p *ngIf="this.reservedCostsType === 'percent' && showDetails && isResidual">
                  Reserved costs ({{ reservedCosts }}%)
                </p>
                <p *ngIf="this.reservedCostsType === 'percent' && showDetails">
                  ({{
                    receivedFunds * (reservedCosts / 100)
                      | number : "1.2-2" : "es"
                  }}) {{ currency }}
                </p>
              </div>
              <p-divider type="dashed" *ngIf="isResidual && residualDistributionSummary.previousleyDistributed!=null"/>
              <div class="summary-item" *ngIf="isResidual && residualDistributionSummary.previousleyDistributed!=null">
                  <p>Previously distributed</p>
                  <p>{{(residualDistributionSummary.previousleyDistributed | number:"1.2-2" : "es") +
                      " " +
                      currency}}</p>
              </div>
              <p-divider type="dashed" *ngIf="isResidual && residualDistributionSummary.previousleyDistributed!=null"/>
              <div class="summary-item">
                <p *ngIf="!isResidual" class="fw-bold">Remuneration to distribute ex. VAT</p>
                <p *ngIf="isResidual" class="fw-bold">Residual funds ex. VAT</p>
                <p class="fw-bold">
                  {{
                    (recievedFundsExcludingVAT | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <div class="summary-item" *ngIf="showDetails">
                <p>VAT Amount</p>
                <p>
                  {{
                    ((RecievedFundVatAmountPrecent / 100) * receivedFunds
                      | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <div class="summary-item">
                <p *ngIf="!isResidual" class="fw-bold">Remuneration to distribute incl. VAT</p>
                <p *ngIf="isResidual" class="fw-bold">Residual funds incl. VAT</p>
                <p class="fw-bold">
                  {{
                    (recievedFundsExcludingVAT +
                      (RecievedFundVatAmountPrecent / 100) * receivedFunds
                      | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <p-divider type="dashed" *ngIf="showDetails" />

              <div class="summary-item" *ngIf="showDetails">
                <p>Your share of Remuneration ex. VAT</p>
                <p>
                  {{
                    (labelShareValue | number : "1.2-2" : "es") + " " + currency
                  }}
                </p>
              </div>

              <div class="summary-item" *ngIf="showDetails">
                <p
                  *ngIf="
                    RecievedFundVatAmountPrecent % 1 !== 0 && !isReverseCharge
                  "
                >
                  VAT Amount ({{
                    RecievedFundVatAmountPrecent | customPercentage
                  }}%)
                </p>
                <p
                  *ngIf="
                    RecievedFundVatAmountPrecent % 1 == 0 && !isReverseCharge
                  "
                >
                  VAT Amount ({{ RecievedFundVatAmountPrecent }}%)
                </p>
                <p *ngIf="isReverseCharge">VAT Amount (Reverse charge)</p>
                <p>
                  {{
                    (labelVATAmountValue | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <div class="summary-item">
                <p class="fw-bold">Your share of Remuneration incl. VAT</p>
                <p class="fw-bold">
                  {{
                    (labelShareIncVATValue | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <p-divider type="dashed" *ngIf="showDetails" />

              <div class="summary-item" *ngIf="showDetails">
                <p>Your share of administration ex. VAT</p>
                <p>
                  ({{
                    LabelAdministratorFeesExcludingVAT
                      | number : "1.2-2" : "es"
                  }})
                  {{ currency }}
                </p>
              </div>

              <div class="summary-item" *ngIf="showDetails">
                <p *ngIf="!isReverseCharge">
                  VAT Amount ({{ AdministrationFeesVatAmountPrecent }}%)
                </p>
                <p *ngIf="isReverseCharge">VAT Amount (Reverse charge)</p>
                <p>
                  ({{
                    LabelAdministratorFeesVATAmount | number : "1.2-2" : "es"
                  }}) {{ currency }}
                </p>
              </div>

              <div class="summary-item">
                <p class="fw-bold">Your share of administration incl. VAT</p>
                <p class="fw-bold">
                  ({{
                    LabelAdministratorFeesIncludingVAT
                      | number : "1.2-2" : "es"
                  }}) {{ currency }}
                </p>
              </div>

              <p-divider type="dashed" *ngIf="showDetails" />

              <div class="summary-item" *ngIf="labelOnAccount > 0">
                <p>On-account</p>
                <p>
                  ({{ (labelOnAccount | number : "1.2-2" : "es") || 0 }})
                  {{ currency }}
                </p>
              </div>

              <div class="summary-item">
                <p class="fw-bold">Your share of distribution incl. VAT</p>
                <p class="fw-bold">
                  {{
                    (labelShareValueIncludingVAT | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <p-divider type="dashed" />

              <div class="summary-item">
                <p>Balances</p>
                <p>
                  {{
                    ((labelPreviousBalance | number : "1.2-2" : "es") || 0) +
                      " " +
                      currency
                  }}
                </p>
              </div>
            </div>
            <br />
            <div class="summary">
              <div class="summary-item">
                <p>
                  Total remuneration<span
                    *ngIf="!distributionHasCorrection"
                    style="color: #495057"
                  >
                    for transfer</span
                  >
                </p>

                <p>
                  {{
                    (labelRemuneratedAmount | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>
            </div>

            <!-- Add PrimeNG switch with label "Show details" -->
            <div class="summary-action">
              <p-inputSwitch
                class="custom-inputswitch"
                id="showDetails"
                [(ngModel)]="showDetails"
              />
              <p class="fw-bold" for="showDetails">Show details</p>
            </div>

            <div class="summary-footer">
              <p>* Parentheses indicate negative amounts</p>
            </div>
          </p-panel>

          <p-panel
            styleClass="summary-panel"
            header="Distribution key"
            [toggleable]="true"
          >
            <div class="details">
              <ng-container *ngFor="let report of distributionKey">
                <div class="d-flex gap-3 align-items-center">
                  <img
                    src="assets/images/{{ organizationPrefix }}/reporters/{{
                      report.reporter
                    }}.svg"
                    style="width: 30px; border-radius: 6px"
                  />
                  <p>
                    {{ report.distributionReportName }}
                  </p>
                </div>
                <div class="summary-item row-color">
                  <p>Weight in distribution</p>
                  <p *ngIf="report.split | isNumber">
                    {{ transform(report.split) | number : "1.2-2" : "es" }}%
                  </p>
                  <p *ngIf="!(report.split | isNumber)">{{ report.split }}</p>
                </div>
                <div class="summary-item row-color">
                  <p>Total Value</p>
                  <p>
                    {{
                      (report.totalFinancialValue | number : "1.2-2" : "es") +
                        " " +
                        currency
                    }}
                  </p>
                </div>

                <div class="summary-item">
                  <p>Your share</p>
                  <p>
                    {{
                      report.labelDistributionReportShare
                        | number : "1.6-6" : "es"
                    }}%
                  </p>
                </div>
                <div class="summary-item">
                  <p>Your value (ex. VAT)</p>
                  <p>
                    {{
                      (report.totalShareValue | number : "1.2-2" : "es") +
                        " " +
                        currency
                    }}
                  </p>
                </div>
                <p-divider type="dashed" />
              </ng-container>
            </div>
            <div class="summary">
              <div class="summary-item">
                <p>Your total share</p>
                <p>{{ totalShare | number : "1.6-6" : "es" }}%</p>
              </div>
              <div class="summary-item">
                <p>Your total value (ex. VAT)</p>
                <p>
                  {{ (totalValue | number : "1.2-2" : "es") + " " + currency }}
                </p>
              </div>
            </div>
          </p-panel>

          <p-panel
            styleClass="summary-panel"
            header="Distribution Summary"
            [toggleable]="true"
            *ngIf="isResidual && residualDistributionSummary.totalDistributionShare !=0 && residualDistributionSummary.totalDistributionShare != null"
          >
            <div class="details">
              <div class="summary-item">
                <p>Total distribution share</p>
                <p>{{residualDistributionSummary.totalDistributionShare | number : "1.6-6" : "es"}}%</p>
              </div>
              
              <p-divider type="dashed" />

              <div class="summary-item" *ngFor="let distribution of residualDistributionSummary.previousDistributionsPublishDateAndRemuneration">
                <p>Distribution on {{distribution.previousDistributionPublishDate | CustomDateWithoutSuffix}}</p>
                <p>{{(distribution.previousDistributionRemuneration| number : "1.2-2" : "es") + " " + currency }}</p>
                
              </div>
              <p-divider type="dashed" />
            </div>
            

            <div class="summary-item">
              <p>This distribution</p>
              <p>{{(residualDistributionSummary.currentResidualRemuneration| number : "1.2-2" : "es") + " " + currency}}</p>
            </div>
            <div class="summary">
              <div class="summary-item">
                <p class="nowrap">Total remuneration across distributions </p>
                <p>{{(residualDistributionSummary.totalResidualRemuneration| number : "1.2-2" : "es") + " " + currency}}</p>
              </div>
            </div>
          </p-panel>

          <p-panel
            styleClass="summary-panel"
            header="Income streams"
            [toggleable]="true"
            *ngIf="incomeStreams.length > 0"
          >
            <div class="details">
              <div *ngFor="let stream of incomeStreams">
                <div class="summary-item">
                  <p>
                    {{ stream.name }}
                    <span *ngIf="stream.year">({{ stream.year }})</span>
                  </p>
                  <p>
                    {{
                      (stream.value | number : "1.2-2" : "es") + " " + currency
                    }}
                  </p>
                </div>
                <div class="summary-item" *ngIf="stream.vatAmount">
                  <span>VAT amount</span>
                  <span>{{
                    (stream.vatAmount | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}</span>
                </div>
              </div>
              <p-divider type="dashed" />
            </div>
            <div class="summary">
              <div class="summary-item">
                <p>Total remuneration (ex. VAT)</p>
                <p>
                  {{
                    (incomeStreamsTotal | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>
            </div>
          </p-panel>

         

          <p-panel
            *ngIf="isAdministrationFeesIncluded"
            styleClass="summary-panel"
            header="Cost of Administration"
            [toggleable]="true"
          >
            <div class="details">
              <div class="summary-item">
                <p>Total costs of administration {{ currentYear }}</p>
                <p>
                  {{
                    (totalCostOfAdministrationInCurrentYear
                      | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>
              <div class="summary-item">
                <p>Costs for offsetting in this distribution</p>
                <p>
                  {{
                    (administrationCost | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>
            </div>
            <div class="summary">
              <div class="summary-item">
                <p>Your percentage share</p>
                <p>
                  {{ (LabelSharePercentage | number : "1.6-6" : "es") + " %" }}
                </p>
              </div>
              <div class="summary-item">
                <p>Your share of costs ex. VAT</p>
                <p>
                  {{
                    (LabelAdministratorFeesExcludingVAT
                      | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>

              <div class="summary-item">
                <p *ngIf="!isReverseCharge" class="fw-light">
                  VAT Amount ({{ AdministrationFeesVatAmountPrecent }}%)
                </p>
                <p *ngIf="isReverseCharge" class="fw-light">
                  VAT Amount (Reverse charge)
                </p>
                <p class="fw-light">
                  {{
                    LabelAdministratorFeesVATAmount | number : "1.2-2" : "es"
                  }}
                  {{ currency }}
                </p>
              </div>

              <div class="summary-item">
                <p>Your share of costs incl. VAT</p>
                <p>
                  {{
                    (LabelAdministratorFeesIncludingVAT
                      | number : "1.2-2" : "es") +
                      " " +
                      currency
                  }}
                </p>
              </div>
            </div>
          </p-panel>
        </div>
      </div>
    </ng-template>
  </p-panel>
</div>
