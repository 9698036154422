<main class="content">
    <!-- Standard header -->
    <section class="header">
      <h1>Settings</h1>
      <p>{{ companyData.brandName}}</p>

      <p-tabView (onChange)="onTabChange($event)">
        <p-tabPanel header="Members" class="liCustom">
         <app-members></app-members>
        </p-tabPanel>
        <p-tabPanel header="Company Settings" class="liCustom">
           <app-company-settings  [companyDetails]="companyData" [isAdmin]="isAdmin"></app-company-settings>
        </p-tabPanel>
        <p-tabPanel header="Advanced" class="liCustom">
          <app-advanced [companyDetails]="companyData"></app-advanced>
        </p-tabPanel>
        <p-tabPanel header="Superadmin settings" class="liCustom" *ngIf="isAdmin">
          <app-super-admin-settings  [companyDetails]="companyData"></app-super-admin-settings>
        </p-tabPanel>
    </p-tabView>
     </section>
      </main>